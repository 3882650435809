import React from "react";
import { Link } from "react-router-dom";
import "../styles/team/team.css";
import "../styles/forms/forms.css";
import Footer from "../components/Footer";
import GeneralNavBar from "../components/GeneralNavBar";

const team = () => {
  return (
    <div className="main-container">
      <GeneralNavBar logo="/images/logo.png" />
      <section className="form-hero">
        <div className="container padding-container">
          <div className="form-hero-main-container">
            <h1>Forms and Resources</h1>
          </div>
        </div>
      </section>
      <section className="main-form">
        <div className="container padding-container">
          <h1>Download our resources and forms below</h1>

          <div className="forms">
            <div className="form">
              <h2 className="form-title">Workers Comp Set Up</h2>
              <Link
                to="https://www.firstmed.health/assets/volumes/staff/Work-Comp-Account-Set-Up-revised-12.19.pdf"
                target="_blank"
              >
                Download
              </Link>
            </div>
            <div className="form">
              <h2 className="form-title">Copay, Coinsurance and Deductible</h2>
              <Link
                to="/documents/Copay, Coinsurance and Deductible.docx"
                target="_blank"
              >
                Download
              </Link>
            </div>
            <div className="form">
              <h2 className="form-title">Good Faith Estimate Disclaimer</h2>
              <Link
                to="/documents/Good Faith Estimate Disclaimer.docx"
                target="_blank"
              >
                Download
              </Link>
            </div>
            <div className="form">
              <h2 className="form-title">HIPAA Notice</h2>
              <Link to="/documents/HIPAA Notice.pdf" target="_blank">
                Download
              </Link>
            </div>
            <div className="form">
              <h2 className="form-title">Medical History</h2>
              <Link to="/documents/Medical History.pdf" target="_blank">
                Download
              </Link>
            </div>
            <div className="form">
              <h2 className="form-title">Sports Physical</h2>
              <Link to="/documents/Sports Physical.pdf" target="_blank">
                Download
              </Link>
            </div>
            <div className="form">
              <h2 className="form-title">Consent to Treat a Minor</h2>
              <Link
                to="/documents/Consent to Treat a Minor.pdf"
                target="_blank"
              >
                Download
              </Link>
            </div>
            <div className="form">
              <h2 className="form-title">New Patient Intake</h2>
              <Link to="/documents/New Patient Intake.pdf" target="_blank">
                Download
              </Link>
            </div>
            <div className="form">
              <h2 className="form-title">DOT Paperwork</h2>
              <Link to="/documents/DOT Paperwork.pdf" target="_blank">
                Download
              </Link>
            </div>
            <div className="form">
              <h2 className="form-title">
                Authorization to Release Medical Records
              </h2>
              <Link
                to="/documents/Authorization to Release Medical Records.docx"
                target="_blank"
              >
                Download
              </Link>
            </div>
            <div className="form">
              <h2 className="form-title">
                Work Comp IRI (Initial Report of Injury)
              </h2>
              <Link
                to="/documents/Work Comp IRI (Initial Report of Injury).docx"
                target="_blank"
              >
                Download
              </Link>
            </div>
            <div className="form">
              <h2 className="form-title">TB Questionnaire</h2>
              <Link to="/documents/TB Questionnaire.docx" target="_blank">
                Download
              </Link>
            </div>
          </div>
        </div>
      </section>

      <Footer logo="/images/logo.png" />
    </div>
  );
};

export default team;
