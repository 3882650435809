import React from "react";
import "../styles/occupational/occupational.css";
import "../styles/team/team.css";
import "../styles/careers.css";
import PinkBgBtn from "../components/PinkBgBtn";
import { CiClock2 } from "react-icons/ci";
import { SlPhone } from "react-icons/sl";
import { FaHandshakeAngle } from "react-icons/fa6";
import { LiaHospitalSymbolSolid } from "react-icons/lia";
import { GoDotFill } from "react-icons/go";
import BorderBtn from "../components/BorderBtn";
import Footer from "../components/Footer";
import TransparentNav from "../components/TransparentNav";
import { useNavigate } from "react-router-dom";

const Occupational = () => {
  const navigate = useNavigate();

  const handleClick = () => {
    navigate("/contact#contact-us");
    // Alternatively, if using query parameters:
    // navigate('/target-page?scrollTo=target-section');
  };
  return (
    <div className="main-container">
      <TransparentNav logo="/images/logo.png" />
      <section className="occupational-hero">
        <div className="container padding-container">
          <div className="occupational-hero-main-container">
            <h1>Occupational Medicine</h1>
            <p>
              We work towards preventing and managing health issues <br />{" "}
              related to your employees.
            </p>
            <PinkBgBtn
              to="/contact#contact-us"
              text="Contact Us"
              // onClick={() => {
              //   window.scroll({
              //     top: 800,
              //     left: 0,
              //     behavior: "smooth",
              //   });
              // }}

              onClick={handleClick}
            />
          </div>
        </div>
      </section>
      <section className="middle-section">
        <div className="container padding-container">
          <div className="our-promises">
            <h1>Our Promises</h1>
            <p>
              When your employees are injured, you need a provider you can
              trust.
            </p>
          </div>

          <div className="promises-cards">
            <div className="promise-card">
              <div className="icon-container">
                <CiClock2 className="icon" />
              </div>
              <div className="text-icon">
                <h3>Prompt Decision</h3>
                <p>
                  When injuries occur, you don't deserve to wait. Only timely,
                  practical, and cost-effective care will do.
                </p>
              </div>
            </div>
            <div className="promise-card">
              <div className="icon-container">
                <SlPhone className="icon" />
              </div>
              <div className="text-icon">
                <h3>Simple Communication</h3>
                <p>
                  We understand that maintaining a healthy staff and bottom line
                  means you need a seamless connection when injuries occur.
                </p>
              </div>
            </div>
            <div className="promise-card">
              <div className="icon-container">
                <FaHandshakeAngle className="icon" />
              </div>
              <div className="text-icon">
                <h3>Strong Partnership</h3>
                <p>
                  Workplace injuries are stressful, so we like to make processes
                  easy. Being a partner means easier paperwork and
                  authorizations.
                </p>
              </div>
            </div>
            <div className="promise-card">
              <div className="icon-container">
                <LiaHospitalSymbolSolid className="icon" />
              </div>
              <div className="text-icon">
                <h3>DOT Certified Providers and Staff</h3>
                <p>
                  We recognize the significance of DOT certification and are
                  responsible for conducting health exams for various drivers,
                  including those operating CMVs, buses, and other vehicles.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="focus">
        <div className="container">
          <div className="paragraph">
            <h1>Our Focus</h1>
            <p>
              FirstMed is a local Urgent Care that’s focused on one thing only:
              healing. All of our Providers are efficient, caring, and well
              versed in workers’ compensation. When injuries occur, we’ve got
              telemedicine as well as walk in capabilities at all of our
              locations, so your staff can receive immediate convenient care and
              get back to work.
            </p>
          </div>
          <div className="list">
            <div className="main-list">
              <div className="first-list">
                <div className="dot-text">
                  <GoDotFill id="dot" />
                  <li>Drug Screens</li>
                </div>
                <div className="dot-text">
                  <GoDotFill id="dot" />
                  <li>
                    Biometric & <br></br>Wellness Testing
                  </li>
                </div>
                <div className="dot-text">
                  <GoDotFill id="dot" />
                  <li>Flu Shots</li>
                </div>
                <div className="dot-text">
                  <GoDotFill id="dot" />
                  <li>Sports physicals</li>
                </div>
                <div className="dot-text">
                  <GoDotFill id="dot" />
                  <li>Lacerations</li>
                </div>
                <div className="dot-text">
                  <GoDotFill id="dot" />
                  <li>Onsite Lab & Xray</li>
                </div>
              </div>

              <div className="second-list">
                <div className="dot-text">
                  <GoDotFill id="dot" />
                  <li>DOT physicals</li>
                </div>
                <div className="dot-text">
                  <GoDotFill id="dot" />
                  <li>COVID Testing</li>
                </div>
                <div className="dot-text">
                  <GoDotFill id="dot" />
                  <li>Breath Alcohol Testing(BAT)</li>
                </div>
                <div className="dot-text">
                  <GoDotFill id="dot" />
                  <li>Worker’s Compensation Claims</li>
                </div>
                <div className="dot-text">
                  <GoDotFill id="dot" />
                  <li>Post-Accident Injury Care</li>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="our-form">
        <div className="container padding-container">
          <h1>Employer Account Setup Form</h1>

          <PinkBgBtn to="/forms" text="Download Form" />
        </div>
      </section>
      <Footer logo="/images/logo.png" />
    </div>
  );
};

export default Occupational;
