import React from "react";
import { Link } from "react-router-dom";
import "../styles/main.css";

const BorderBtn = (props) => {
  return (
    <Link target={props.target} to={props.to} className="border-btn-link">
      {props.text}
    </Link>
  );
};

export default BorderBtn;
