import React from "react";
import HomeNavbar from "../components/HomeNavbar";
import Footer from "../components/Footer";
import "../styles/main.css";
import "../styles/home/home.css";
import { FaCheckCircle } from "react-icons/fa";
import { FaArrowRightLong } from "react-icons/fa6";
import PinkBgBtn from "../components/PinkBgBtn";
import WhiteBgBtn from "../components/WhiteBgBtn";
import FacilitiesCard from "../components/FacilitiesCard";
import Testimonial from "../components/Testimonial";
import HealthPartner from "../components/HealthPartner";
import FacilityPopup from "../components/FacilitiesPopup";

import { useState } from "react";
import PrimaryCareFacilityPopup from "../components/PrimaryCareFacilityPopup";
const Home = () => {
  const [popupInfo, setPopupInfo] = useState(null);
  const [primaryPopup, setPrimaryPopup] = useState(null);

  const openPopup = (info) => {
    setPopupInfo(info);
  };

  const openPrimaryPopup = (info) => {
    setPrimaryPopup(info);
  };
  const closePrimaryPopup = () => {
    setPrimaryPopup(null);
  };
  const closePopup = () => {
    setPopupInfo(null);
  };
  return (
    <div className="main-container">
      <HomeNavbar />
      <section className="home-hero ">
        <div className="container padding-container">
          <div className="hero-content">
            <div className="hero-logo">
              <img src="/images/first-med-logo-big.png" alt="First med logo" />
            </div>

            <p>
              <span>F</span>irst<span>M</span>ed is more than just a clinic;
              it’s a place where your health and wellness are our top
              priorities. <span>F</span>irst<span>M</span>ed is dedicated to
              providing comprehensive medical care for individuals and families.
            </p>
            <div className="hero-button">
              <PinkBgBtn to="#facility" text="Book An Appointment" />
            </div>
          </div>
        </div>
      </section>
      <section className="offer">
        <div className="container padding-container">
          <h1>What we offer</h1>
          <div className="offer-main">
            <div className="offer-img-container">
              <img
                src="/images/services-2.png"
                alt="Lady veiwing xray result"
              />
            </div>
            <div className="offer-text-content">
              <h1>Urgent Care</h1>

              <div className="styled-text">
                <p>
                  Urgent Care is a service designed for immediate medical
                  attention and treatment. Urgent Care services cover a variety
                  of non-life threatening conditions and other general medical
                  needs that arise in unforeseen situations.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="primary-care">
        <div className="container padding-container">
          <div className="primary-care-text-content">
            <h1>Primary Care</h1>
            <p>
              Primary care is a crucial component of healthcare that focuses on
              the provision of comprehensive, continuous, and accessible
              healthcare services to individuals, families, and communities. It
              serves as the first point of contact for individuals seeking
              medical care and is intended to address a wide range of health
              needs, including preventive care, acute illnesses, and chronic
              conditions.
            </p>
            <div className="primary-care-row">
              <div className="check-icon-container">
                <FaCheckCircle className="check-icon" />
              </div>

              <p>
                Primary care emphasizes preventive measures, such as
                vaccinations, screenings, and health education, to identify and
                manage potential health risks before they become major issues.
              </p>
            </div>
            <div className="primary-care-row">
              <div className="check-icon-container">
                <FaCheckCircle className="check-icon" />
              </div>
              <p>
                Primary care addresses immediate healthcare needs, such as
                common illnesses, injuries, and infections, providing timely and
                appropriate interventions.
              </p>
            </div>
          </div>
          <div className="primary-care-img-container">
            <img
              src="/images/family-doctor-office.webp"
              alt="Patient Lying on Swingbed"
            />
          </div>
        </div>
      </section>
      <section className="occupational">
        <div className="container padding-container">
          <div className="occupational-img-container">
            <img src="/images/services-1.png" alt="Man working on wires" />
          </div>
          <div className="occupational-text-content">
            <h1>Occupational Medicine</h1>
            <p>
              Occupational Medicine is a branch of medicine that focuses on the
              health and well-being of workers in the context of their
              workplace. Here’s Occupational Medicine goals:
            </p>
            <div className="arrow-row">
              <div className="arrow-icon-container">
                <FaArrowRightLong className="arrow-icon" />
              </div>
              <p>Prevent and manage health issues related to work</p>
            </div>
            <div className="arrow-row">
              <div className="arrow-icon-container">
                <FaArrowRightLong className="arrow-icon" />
              </div>
              <p>Promote a safe and healthy working environment</p>
            </div>
            <div className="arrow-row">
              <div className="arrow-icon-container">
                <FaArrowRightLong className="arrow-icon" />
              </div>
              <p>Risk Assessment and Management</p>
            </div>
          </div>
        </div>
      </section>
      <section className="facilities" id="facility">
        <div className="container padding-container">
          <h1>Our Facilities</h1>
          <div className="facilities-card-container">
            <FacilitiesCard
              facility="Urgent Care - Northwest"
              address="4510 NW 39TH ST."
              location="OKC, OK 73122"
              img="/images/urgent-care-full-facility.webp"
              to="https://www.solvhealth.com/r/book-online/gZmPXp/yRSgxxYGPtbuXrGvTi7rE/slots/today"
              onClick={() =>
                openPopup({
                  facility: "Urgent Care - Northwest",
                  address: "4510 NW 39TH ST.",
                  location:
                    "Same-day appointments, walk-ins welcome, virtual visits available, rapid covid testing, onsite xray, onsite lab, drug screens, sports physicals, DOT physicals, Breath Alcohol Test (BAT), and work comp injuries",
                  apiEndpoint:
                    "https://manage.solvhealth.com/partner/next-available/gZmPXp?partner_id=gZmPXp",

                  operationHours: (
                    <p>
                      8:00 a.m. to 7:00 p.m | Daily
                      <br />
                      <br />
                      TRY OUT A VIRTUAL VISIT AND SEE A PROVIDER FROM THE
                      COMFORT OF YOUR HOME!
                    </p>
                  ),
                })
              }
            />
            <FacilitiesCard
              facility="Urgent Care - South Walker"
              address="7807 S Walker Ave"
              location="OKC, OK 73139"
              img="/images/both-primary-urgent.webp"
              to="https://www.solvhealth.com/r/book-online/pYOeR0/AkOpTB5ecPbcEg7RPsfa5/slots/today"
              onClick={() =>
                openPopup({
                  facility: "Urgent Care - South Walker",
                  address: "7807 S Walker Ave",
                  location:
                    "Same-day appointments, walk-ins welcome, virtual visits available, rapid covid testing, onsite xray, onsite lab, drug screens, sports physicals, DOT physicals, Breath Alcohol Test (BAT), and work comp injuries",
                  operationHours: (
                    <p>
                      8:00 a.m. to 7:00 p.m | Daily
                      <br />
                      <br />
                      TRY OUT A VIRTUAL VISIT AND SEE A PROVIDER FROM THE
                      COMFORT OF YOUR HOME!
                    </p>
                  ),

                  apiEndpoint:
                    "https://manage.solvhealth.com/partner/next-available/pYOeR0?partner_id=pYOeR0",
                })
              }
            />
            <FacilitiesCard
              facility="Urgent Care - South Western"
              address="11521 S. Western"
              location="OKC, OK 73170"
              img="/images/urgent-care-facility.webp"
              to="https://www.solvhealth.com/r/book-online/AMMOwO/zRvYQ9vRwzaDClnbRDFbA/slots/today"
              onClick={() =>
                openPopup({
                  facility: "Urgent Care - South Western",
                  address: "11521 S. Western",
                  location:
                    "Same-day appointments, walk-ins welcome, virtual visits available, rapid covid testing, onsite xray, onsite lab, drug screens, sports physicals, DOT physicals, and work comp injuries",

                  apiEndpoint:
                    "https://manage.solvhealth.com/partner/next-available/AMMOwO?partner_id=AMMOwO",
                  operationHours: (
                    <p>
                      8:00 a.m. to 7:00 p.m | Daily
                      <br />
                      <br />
                      TRY OUT A VIRTUAL VISIT AND SEE A PROVIDER FROM THE
                      COMFORT OF YOUR HOME!
                    </p>
                  ),
                  goToDirectLink:
                    "www.solvhealth.com/r/book-online/AMMOwO/RgR7-Pkjin-zt-EOT_Foa/slots/today",
                })
              }
            />
            <FacilitiesCard
              facility="Urgent Care - Shawnee"
              address="4010 N. Kickapoo #3"
              location="Shawnee, OK 74804"
              img="/images/shawnee-facility.jpg"
              to="https://www.solvhealth.com/book-online/0EBk2w"
              onClick={() =>
                openPopup({
                  facility: "Urgent Care - Shawnee",
                  address: "4010 N. Kickapoo #3 Shawnee, OKC, 74804",
                  location:
                    "Same-day appointments, walk-ins welcome, virtual visits available, rapid covid testing, onsite xray, onsite lab, drug screens, sports physicals, DOT physicals, Breath Alcohol Test (BAT), and work comp injuries",

                  apiEndpoint:
                    "https://manage.solvhealth.com/partner/next-available/0EBk2w?partner_id=0EBk2w",
                  operationHours: (
                    <p>
                      8:00 a.m. to 7:00 p.m | Daily
                      <br />
                      <br />
                      TRY OUT A VIRTUAL VISIT AND SEE A PROVIDER FROM THE
                      COMFORT OF YOUR HOME!
                    </p>
                  ),
                  goToDirectLink:
                    "https://www.solvhealth.com/book-online/0EBk2w",
                })
              }
            />
            <FacilitiesCard
              facility="Primary Care - Cohesive Family Medicine
              "
              address="2508 N. Harrison St."
              location="Shawnee, OK 74804"
              img="/images/cohesive-medicine-facility.webp"
              appointment="Monday, Feb 26 | 7:30Pm"
              onClick={() =>
                openPrimaryPopup({
                  facility: "Primary Care - Eastern",
                  address: "13310 N. Eastern Ave.",
                  location: `Diagnostic and therapeutic services for illnesses, diseases, conditions, signs & symptoms, and minor injuries <br/>
                    •	Complete Medical examinations <br/>
                    
                    •	Assessments of health status <br/>
                    •	Complete lab services including covid, flu, strep and UA <br/>
                    •	Ordering routine diagnostic studies <br/>
                    •	Diagnosis and/or treatment of common acute medical conditions <br/>
                    •	Diagnosis and/or treatment of common chronic medical conditions <br/>
                    •	Health status, family planning, and lifestyle <br/>
                    •	Coordinating care with other physicians, providers, and facilities <br/>
                    •	Medication management <br/>
                    •	Minor procedures <br/>
                    `,
                  call: "Call 405-585-2030",

                  operationHours: (
                    <p>
                      Monday through Friday 8 AM to 5 PM with a lunch break from
                      12 to 1
                    </p>
                  ),

                  showButton: true,
                  btnText: "Cohesive Family Medicine Website",
                  btnTo: "https://acclaimedfamilymedicine.com/",
                })
              }
            />
            <FacilitiesCard
              facility="Primary Care – Graham Family Medicine
              "
              showBottom="true"
              address="1002 N E Hwy 66 #2"
              location="Sayre, OK 73662"
              img="/images/graham-facility.webp"
              appointment="Monday, Feb 26 | 7:30Pm"
              onClick={() =>
                openPrimaryPopup({
                  facility: "Primary Care – Graham Family Medicine",
                  address: "1002 N E Hwy 66 #2, Sayre, OK 73662",
                  location: `Diagnostic and therapeutic services for illnesses, diseases, conditions, signs & symptoms, and minor injuries<br/>
                  •	Complete Medical examinations<br/>
                  •	Assessments of health status<br/>
                  •	Complete lab services including covid, flu, strep and UA<br/>
                  •	Ordering routine diagnostic studies<br/>
                  •	Diagnosis and/or treatment of common acute medical conditions<br/>
                  •	Diagnosis and/or treatment of common chronic medical conditions<br/>
                  •	Health status, family planning, and lifestyle<br/>
                  •	Coordinating care with other physicians, providers, and facilities<br/>
                  •	Medication management<br/>
                  •	Minor procedures<br/>
                  •	Medicare Wellness Exams <br/>
                  •	Onsite xray <br/>
                  
                    `,
                  call: "Call 580-928-2208",

                  operationHours: (
                    <p>
                      Monday through Friday 8 AM to 5 PM with a lunch break from
                      12 to 1
                    </p>
                  ),
                })
              }
            />

            {/* <FacilitiesCard
              facility="Primary Care - Cohesive Family Medicine
              "
              address="2508 N. Harrison St."
              location="Shawnee, OK 74804"
              img="/images/cohesive-medicine-facility.webp"
              appointment="Monday, Feb 26 | 7:30Pm"
              onClick={() =>
                openPrimaryPopup({
                  facility: "Primary Care - Eastern",
                  address: "13310 N. Eastern Ave.",
                  location: "TBA",

                  operationHours: <p>TBA</p>,
                })
              }
            /> */}
            {/* <FacilitiesCard
              facility="Primary Care - Eastern"
              address="13310 N. Eastern Ave."
              location="Oklahoma City, OK 73131"
              img="/images/primare-care-facility.webp"
              appointment="Monday, Feb 26 | 7:30Pm"
              onClick={() =>
                openPrimaryPopup({
                  facility: "Primary Care - Eastern",
                  address: "13310 N. Eastern Ave.",
                  location:
                    "Adult Primary Care Services Accepts Medicare and most commercial insurances",

                  operationHours: (
                    <p>
                      Monday - Thursday 8:30am - 12pm, 1pm - 3pm    <br />
                      Closed Lunch 12pm - 1pm <br />
                      Friday - 830am - 1pm
                    </p>
                  ),
                })
              }
            /> */}
          </div>
          {popupInfo && <FacilityPopup {...popupInfo} onClose={closePopup} />}

          {primaryPopup && (
            <PrimaryCareFacilityPopup
              {...primaryPopup}
              onClose={closePrimaryPopup}
            />
          )}
        </div>
      </section>
      <Testimonial />

      <Footer logo="/images/logo.png" />
    </div>
  );
};

export default Home;
