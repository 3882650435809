import React from "react";
import PinkBgBtn from "./PinkBgBtn";
import BorderBtn from "./BorderBtn";
import WhiteBgBtn from "./WhiteBgBtn";

const PrimaryCareFacilityPopup = ({
  operationHours,
  onClose,
  location,
  call,
  showButton,
  btnText,
  btnTo,
}) => {
  return (
    <div className="facility-popup">
      <div className="overlay" onClick={onClose}></div>
      <div className="popup-content">
        <h4>{call}</h4>
        <h2 className="title">Book your appointment</h2>
        <hr />
        <button className="close-btn" onClick={onClose}>
          ×
        </button>
        <h4>Location Services</h4>
        <p dangerouslySetInnerHTML={{ __html: location }} />

        <h4>Hours of Operation</h4>
        <div>{operationHours}</div>

        {showButton && <BorderBtn target="_blank" to={btnTo} text={btnText} />}
      </div>
    </div>
  );
};

export default PrimaryCareFacilityPopup;
