import React, { useState, useEffect } from "react";

const FacilityPopup = ({
  operationHours,
  location,
  apiEndpoint,
  onClose,
  goToDirectLink,
  call,
}) => {
  const [appointment, setAppointment] = useState("Book Now");
  const [loading, setLoading] = useState(true);
  const [goToLink, setGoToLink] = useState("#");

  useEffect(() => {
    const fetchAppointment = async () => {
      try {
        const response = await fetch(apiEndpoint);
        const data = await response.json();
        if (data.length > 0) {
          const appointmentData = data[0];
          const appointmentDate = new Date(appointmentData.epoch_time);
          const formattedDate = formatAppointmentDate(appointmentDate);
          const goToLink = appointmentData.booking_url;
          setGoToLink(goToLink);
          setAppointment(formattedDate);
        } else {
          setAppointment("Book Now");
        }
      } catch (error) {
        console.error("Error fetching appointment data:", error);
        setAppointment("Book Now");
      } finally {
        setLoading(false);
      }
    };

    fetchAppointment();
  }, [apiEndpoint]);

  const formatAppointmentDate = (date) => {
    const days = [
      "Sunday",
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
    ];
    const months = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];

    const dayOfWeek = days[date.getDay()];
    const month = months[date.getMonth()];
    const dayOfMonth = date.getDate();
    const year = date.getFullYear();
    const hours = date.getHours();
    const minutes = date.getMinutes();
    const ampm = hours >= 12 ? "pm" : "am";
    const formattedHours = hours % 12 || 12;
    const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;

    return `${dayOfWeek}, ${month.toUpperCase()}. ${dayOfMonth} | ${formattedHours}:${formattedMinutes} ${ampm}`;
  };

  return (
    <div className="facility-popup">
      <div className="overlay" onClick={onClose}></div>
      <div className="popup-content">
        <h3 className="title">{call}</h3>
        <h2 className="title">Book your appointment</h2>
        <hr />
        <button className="close-btn" onClick={onClose}>
          ×
        </button>

        <h4>Next available appointment</h4>

        {loading ? (
          <p className="loader">Getting appointment...</p>
        ) : appointment !== "Book Now" && !loading ? (
          <a
            href={`https://${goToLink}`}
            className="book-now-button"
            target="_blank"
            rel="noopener noreferrer"
          >
            {appointment}
          </a>
        ) : (
          <a
            href={`${goToDirectLink}`}
            className="book-now-button"
            target="_blank"
            rel="noopener noreferrer"
          >
            Book Now
          </a>
        )}
        <h4>Location Services</h4>
        <p dangerouslySetInnerHTML={{ __html: location }} />

        <h4>Hours of Operation</h4>
        <div>{operationHours}</div>
      </div>
    </div>
  );
};

export default FacilityPopup;
