import { BrowserRouter, Routes, Route } from "react-router-dom";
import React from "react";
import Home from "./pages/home";
import Careers from "./pages/careers";
import UrgentCare from "./pages/urgentCare";
import PrimaryCare from "./pages/primaryCare";
import Team from "./pages/team";
import Occupational from "./pages/occupational";
import Contact from "./pages/contact";
import Forms from "./pages/forms";
import ScrollToHashElement from "./components/ScrollToHashElement";

function App() {
  return (
    <BrowserRouter>
      <ScrollToHashElement />

      <div>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/careers" element={<Careers />} />
          <Route path="/urgent-care" element={<UrgentCare />} />
          <Route path="/primary-care" element={<PrimaryCare />} />
          <Route path="/team" element={<Team />} />
          <Route path="/careers" element={<Careers />} />
          <Route path="/occupational" element={<Occupational />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/forms" element={<Forms />} />
        </Routes>
      </div>
    </BrowserRouter>
  );
}

export default App;
