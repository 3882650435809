import React from "react";
import "../styles/main.css";
import { Link } from "react-router-dom";
const Footer = (props) => {
  return (
    <footer>
      <div className="container padding-container">
        <div className="footer-first">
          <img src={props.logo} alt="Logo" />
          <p>
            FirstMed is dedicated to providing comprehensive medical care for
            individuals and families.
          </p>
        </div>
        <div className="footer-second">
          <h3>Important Links</h3>
          <ul>
            <li>
              <a href="/" className="link">
                Home
              </a>
            </li>
            <li>
              <a href="/urgent-care" className="link">
                Urgent Care
              </a>
            </li>
            <li>
              <a href="/primary-care" className="link">
                Primary Care
              </a>
            </li>
            <li>
              <a href="/team" className="link">
                Meet Our Team
              </a>
            </li>
            <li>
              <a href="/occupational" className="link">
                Occupational Medicine
              </a>
            </li>
            <li>
              <a href="/careers" className="link">
                Careers
              </a>
            </li>
            <li>
              <a href="/forms" className="link">
                Forms
              </a>
            </li>
            <li>
              <a href="/contact" className="link">
                Contact
              </a>
            </li>
          </ul>
        </div>
        <div className="footer-third">
          <h3>Locations</h3>
          <ul>
            <h4>FirstMed Urgent Care</h4>
            <li>7807 S. Walker, OKC 73139</li>
            <li>11521 S. Western, OKC, OK 73170</li>
            <li>4510 NW 39th St., OKC, OK 73122</li>
            <li>4010 N. Kickapoo #3, Shawnee, OK 74804</li>
          </ul>
          <ul>
            <h4>Graham Family Medicine</h4>
            <li>1002 NE Highway 66 #2, Sayre, OK 73662</li>
          </ul>
          <ul>
            <h4>Cohesive Family Medicine</h4>
            <li>2508 N. Harrison St., Shawnee, OK 74804</li>
          </ul>
        </div>
        <div className="footer-fourth">
          <h3>Contact Us</h3>
          <ul>
            <li>Urgent Care: +1 405 759 4300 </li>
            <li>Contact@firstmed.health </li>
          </ul>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
