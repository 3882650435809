import React from "react";
import "../styles/main.css";
import { FiPhone } from "react-icons/fi";
import { LuSendHorizonal } from "react-icons/lu";
import { Link } from "react-router-dom";
import { RxHamburgerMenu } from "react-icons/rx";
import { HiOutlineXMark } from "react-icons/hi2";
import { useState } from "react";

const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };
  return (
    <>
      <header className="home-header">
        <div className="nav-info ">
          <div className="container padding-container">
            <Link to="/">
              <img src="/images/logo.png" alt="Logo" />
            </Link>

            <div className="contact-info">
              <div>
                <span className="grey-text">Urgent Care:</span>
                <FiPhone className="icon" />
                <p>+1 405 759 4300 </p>
              </div>
              <div>
                <LuSendHorizonal className="icon" />
                <p>Contact@firstmed.health</p>
              </div>
            </div>
            <div
              className={`hamburger-container ${
                isOpen ? "hidden" : "hamburger-container"
              }`}
              onClick={toggleMenu}
            >
              <RxHamburgerMenu className="hamburger" />
            </div>
            <div
              className={`close-hamburger-container ${
                !isOpen ? "hidden" : "hamburger-container"
              }`}
              onClick={toggleMenu}
            >
              <HiOutlineXMark className="close-hamburger" />
            </div>
          </div>
        </div>
        <nav className={`${isOpen ? "navOpen" : ""}`}>
          <div className="container padding-container">
            <ul>
              <li>
                <Link
                  to="/"
                  className="link"
                  onClick={() => {
                    window.scroll({
                      top: 0,
                      left: 0,
                      behavior: "smooth",
                    });
                  }}
                >
                  Home
                </Link>
              </li>
              <li>
                <Link
                  to="/urgent-care"
                  className="link"
                  onClick={() => {
                    window.scroll({
                      top: 0,
                      left: 0,
                      behavior: "smooth",
                    });
                  }}
                >
                  Urgent Care
                </Link>
              </li>
              <li>
                <Link
                  to="/primary-care"
                  className="link"
                  onClick={() => {
                    window.scroll({
                      top: 0,
                      left: 0,
                      behavior: "smooth",
                    });
                  }}
                >
                  Primary Care
                </Link>
              </li>
              <li>
                <Link
                  to="/team"
                  className="link"
                  onClick={() => {
                    window.scroll({
                      top: 0,
                      left: 0,
                      behavior: "smooth",
                    });
                  }}
                >
                  Our Team
                </Link>
              </li>
              <li>
                <Link
                  to="/occupational"
                  className="link"
                  onClick={() => {
                    window.scroll({
                      top: 0,
                      left: 0,
                      behavior: "smooth",
                    });
                  }}
                >
                  Occupational Medicine
                </Link>
              </li>
              <li>
                <Link
                  to="/careers"
                  className="link"
                  onClick={() => {
                    window.scroll({
                      top: 0,
                      left: 0,
                      behavior: "smooth",
                    });
                  }}
                >
                  Careers
                </Link>
              </li>
              <li>
                <Link
                  to="/forms"
                  className="link"
                  onClick={() => {
                    window.scroll({
                      top: 0,
                      left: 0,
                      behavior: "smooth",
                    });
                  }}
                >
                  Urgent Care Forms
                </Link>
              </li>
              <li>
                <Link
                  to="/contact"
                  className="link"
                  onClick={() => {
                    window.scroll({
                      top: 0,
                      left: 0,
                      behavior: "smooth",
                    });
                  }}
                >
                  Contact
                </Link>
              </li>
            </ul>
          </div>
        </nav>
      </header>
    </>
  );
};

export default Navbar;
