import React from "react";
import BorderBtn from "./BorderBtn";
import "../styles/main.css";

const FacilitiesCard = (props) => {
  return (
    <div className="facilities-card" onClick={props.onClick}>
      <div className="facilities-img-container">
        <img
          className={props.showBottom ? "show-bottom" : ""}
          src={props.img}
          alt="Facilities Image"
          height="500"
          width="500"
        />
        <div className="img-overlay">
          <p>Next Available Appointment</p>
          <h4>{props.appointment}</h4>
        </div>
      </div>
      <div className="facilities-text-content">
        <h4>{props.facility}</h4>
        <span>{props.address}</span>
        <span>{props.location}</span>
      </div>
    </div>
  );
};

export default FacilitiesCard;
